import React from 'react';

function Notification({ title, message, onConfirm, onCancel, logo, xLanguage, alreadyRescheduled, alreadyRescheduledValue, accountEmail, providerName }) {
  console.log("Logo:", logo);

  const notificationPreferencesUrl = `https://www.myappointment.notify-pro.com/optInfo?accountEmail=${encodeURIComponent(accountEmail)}&providerName=${encodeURIComponent(providerName)}`;


  const buttonTexts = {
    English: {
      notificationPreferences: "CLICK HERE TO EDIT YOUR NOTIFICATION PREFERENCES",
      // closeWindow: "CLOSE WINDOW"
    },
    Spanish: {
      notificationPreferences: "PRESIONE AQUÍ PARA EDITAR SUS PREFERENCIAS DE NOTIFICACIÓN",
      // closeWindow: "CERRAR VENTANA"
    }
  };

  const currentTexts = buttonTexts[xLanguage] || buttonTexts['Spanish']; // Default to English if `lang` is not recognized



  let confirmText = "Favor de confirmar su cita / Please confirm your appointment:";
  let buttonTextConfirm = "CONFIRM";
  let buttonTextCancel = "CANCEL";
  let rescheduledMessage = "";

  if (xLanguage === "Spanish") {
    confirmText = "Favor de confirmar su cita:";
    buttonTextConfirm = "CONFIRMAR";
    buttonTextCancel = "CANCELAR";
    rescheduledMessage = `¡Esta cita ya ha sido reprogramada para el ${alreadyRescheduledValue}!. Si necesita más ayuda con su cita, no dude en contactarnos a nuestra oficina durante horas laborales.`;
  } else if (xLanguage === "English") {
    confirmText = "Please confirm your appointment:";
    rescheduledMessage = `This appointment has already been rescheduled to ${alreadyRescheduledValue}!. If you need more help regarding your appointment, don't hesitate to contact us at our office during business hours.`;
  }

  return (
    <div className="notification">
      <img src={logo} alt="Logo" className="logo"/>
      <h1>{title}</h1>
      <div className="message-container">
        {alreadyRescheduled ? (
          <>
            <p className="font-bold text-red-600 uppercase text-center">
              {rescheduledMessage}
            </p>
            <a 
              href={notificationPreferencesUrl} 
              className="notif-preferences-button" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <strong>{currentTexts.notificationPreferences}</strong>
            </a>
          </>
          
        ) : (
          <>
            <p>{message}</p>
            <p><strong>{confirmText}</strong></p>
            <div className="button-container">
              <button className="confirm" onClick={onConfirm}>
                <strong>{buttonTextConfirm}</strong>
              </button>
              <button className="cancel" onClick={onCancel}>
                <strong>{buttonTextCancel}</strong>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Notification;

// function Notification({ title, message, onConfirm, onCancel, logo, xLanguage, alreadyRescheduled, alreadyRescheduledValue }) {
//   console.log("Logo:", logo)
//   let confirmText = "Favor de confirmar su cita / Please confirm your appointment:";
//   let buttonTextConfirm = "CONFIRM";
//   let buttonTextCancel = "CANCEL";

//   if (xLanguage === "Spanish") {
//     confirmText = "Favor de confirmar su cita:";
//     buttonTextConfirm = "CONFIRMAR";
//     buttonTextCancel = "CANCELAR";
//   } else if (xLanguage === "English") {
//     confirmText = "Please confirm your appointment:";
//     // The English buttons remain as "CONFIRM" and "CANCEL", so you don't need to change them here.
//   }

//   return (
//     <div className="notification">
//       <img src={logo} alt="Logo" className="logo"/>
//       <h1>{title}</h1>
//       <div className="message-container">
//         <p>{message}</p>
//       </div>
//       <p><strong>{confirmText}</strong></p>
//       <div className="button-container">
//         <button className="confirm" onClick={onConfirm}>
//           <strong>{buttonTextConfirm}</strong>
//         </button>
//         <button className="cancel" onClick={onCancel}>
//           <strong>{buttonTextCancel}</strong>
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Notification;

  