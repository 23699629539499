import React from 'react';

function OtherMessageCard({ message }) {
    return (
        <div className="message-card-container">
            <div className="message-card">
                <p>{message}</p>
            </div>
            
        </div>
    );
}

export { OtherMessageCard };

