import React, { useState, useEffect, startTransition } from 'react';
import axios from 'axios';
import './ClientInfoForm.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { OtherMessageCard } from './OtherMessageCard';



function RescheduleFromApptForm() {
    const [language, setLanguage] = useState('Spanish');
    const [logo, setLogo] = useState('')
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [optIn, setOptIn] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstLastNameUserInput, setFirstLastNameUserInput] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfBirthInput, setDateOfBirthInput] = useState('');
    const [dataExists, setDataExists] = useState(false);
    const [message, setMessage] = useState('');
    const [showMessageCard, setShowMessageCard] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [oldDate, setOldDate] = useState("");
    const [patientEmail, setPatientEmail] = useState("");
    const [encType, setEncType] = useState("");
    const [fullName, setFullName] = useState("");
    const [assocCal, setAssocCal] = useState("");
    const [xLanguage, setXLanguage] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [lastSearchDate, setLastSearchDate] = useState(null);
    const [dotCount, setDotCount] = useState(0);
    const [linkedEncounterTypes, setLinkedEncounterTypes] = useState([]);
    const [oldTime, setOldTime] = useState("");
    const [oldEncType, setOldEncType] = useState("");
    const [alreadyRescheduled, setAlreadyRescheduled] = useState(false);
    const [alreadyRescheduledValue, setAlreadyRescheduledValue] = useState("");
    const [correctedNumber, setCorrectedNumber] = useState(false);
    const [originalOtherInfo, setOriginalOtherInfo] = useState("");
    const [originallyCreatedBy, setOriginallyCreatedBy] = useState("");
    const [originalApptDocId, setOriginalApptDocId] = useState("");
    const [aptSpecLabel, setAptSpecLabel] = useState("");
    const [rescheduleFromAppt, setRescheduleFromAppt] = useState(false);
    const [orAppt, setOrAppt] = useState(false);
    const [isDateEmpty, setIsDateEmpty] = useState(true);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isAppleDevice, setIsAppleDevice] = useState(false);
    const [firstNameInput, setFirstNameInput] = useState('');
    const [apptFirstName, setApptFirstName] = useState('');
    const [apptMiddleName, setApptMiddleName] = useState('');
    const [apptLastName, setApptLastName] = useState('');







    const urlParams = new URLSearchParams(window.location.search);
    const index = urlParams.get("index");
    const accountEmail = urlParams.get('accountEmail');
    const providerName = urlParams.get('providerName');
    const selectedCalendar = urlParams.get('calendar');

   



    useEffect(() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent.toLowerCase()
      );
      setIsDesktop(!isMobileDevice);
    
      const isApple = /iphone|ipad|ipod/i.test(userAgent.toLowerCase());
      setIsAppleDevice(isApple);
    }, []);
    


// useEffect(() => {

//     alert(alreadyRescheduledValue + " " + alreadyRescheduled);

// }, [alreadyRescheduled]);


    useEffect(() => {
        loadLogo();

    
        if (otpVerified) {
            fetchData();
            // loadData();
        }
    
        // if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
        //     handleSendOtp();
        // }
    }, [otpVerified, phoneNumber]);

    useEffect(() => {
        if (firstLastNameUserInput.length > 0 && dateOfBirth.length > 0) {
            loadData();
        }
    }, [firstLastNameUserInput, dateOfBirth]);


    async function loadLogo() {
        // console.log(providerName)
        try {
            const response = await axios.get('https://nproapiserver.herokuapp.com/getLogo', { params: { accountEmail, providerName } });
    
            if (response.data) {
                setLogo(response.data.smsConfirmLogo);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Handle the error for 404 status
            } else {
                console.error("An error occurred:", error);
            }
        }
    }
    

    const normalizeString = (str) => {
        const accents = 'ÁÀÂÄáàâäÉÈÊËéèêëÍÌÎÏíìîïÓÒÔÖóòôöÚÙÛÜúùûü';
        const without = 'AAAAaaaaEEEEeeeeIIIIiiiiOOOOooooUUUUuuuu';
    
        return str
            .split('')
            .map((char, index) => {
                const accentIndex = accents.indexOf(char);
                return accentIndex !== -1 ? without[accentIndex] : char;
            })
            .join('')
            .toLowerCase();
    };

    async function loadData() {
        try {
            const response = await axios.get('https://nproapiserver.herokuapp.com/getDataFromAppt', {
                params: {
                    index: index,
                    providerName: providerName,
                    accountEmail: accountEmail,
                    calendar: selectedCalendar
                },
            });
    
            const data = response.data;
            // console.log(data);

            // console.log(data.dateOfBirth);
            // console.log(dateOfBirthInput);
            // console.log(data.name.split(/[\s-]/)[1].toLowerCase());
            // console.log(firstLastNameUserInput.toLowerCase());


                if (data.dateOfBirth !== dateOfBirthInput) {
                alert(language === 'English' ? 'The details you entered do not match the ones in our database. Please try again.' : 'Los detalles que ingresó no coinciden con los que están en nuestra base de datos. Por favor, intente de nuevo.');
                window.location.reload();
                return
            }
            if (data.name.split(/[\s-]/)[1].toLowerCase() !== firstLastNameUserInput.toLowerCase()) {
                alert(language === 'English' ? 'The details you entered do not match the ones in our database. Please try again.' : 'Los detalles que ingresó no coinciden con los que están en nuestra base de datos. Por favor, intente de nuevo.');
                window.location.reload();
                return
            }

            // Storing data into state variables
            if (data.orAppt === 'true') {
                alert(language === 'English' ? 'Cannot auto-reschedule an OR appointment. Please contact us if you want to make changes.' : 'No se puede auto-reprogramar una cita de OR. Por favor contactenos si desea relizar algun cambio.');
                window.location.reload();
                return
            }
            setOrAppt(data.orAppt);
            setRescheduleFromAppt(data.rescheduleFromAppt);
            setOriginalOtherInfo(data.originalOtherInfo);
            setOriginallyCreatedBy(data.originallyCreatedBy);
            setOriginalApptDocId(data.originalApptDocId);
            setAptSpecLabel(data.aptSpecLabel);
            setXLanguage(data.xLanguage);
            setOldTime(data.time);
            setOldEncType(data.encType);
            setOldDate(data.date);
            setPatientEmail(data.email);
            setEncType(data.encType);
            setFullName(data.name);
            // setApptFirstName(data.firstName);
            // setApptMiddleName(data.middleName);
            // setApptLastName(data.lastName);
            setAssocCal(data.assocCal);
            // console.log(data.shownEncTypeData);
            setLinkedEncounterTypes(data.shownEncTypeData);
            if (data.alreadyRescheduled?.length > 0) {
              setAlreadyRescheduled(true);
              setAlreadyRescheduledValue(data.alreadyRescheduled);
            }
        } catch (error) {
            console.error("There was an error!", error);
        }
    }
    
    
    const fetchData = async () => {
        try {
            const response = await axios.get('https://nproapiserver.herokuapp.com/getClientDetails', { params: { phoneNumber, accountEmail, providerName, dateOfBirth: dateOfBirthInput, firstNameInput } });
    
            if (response.data) {
                // Extract the first last name
                const firstLastName = response.data.lastName.split(/[\s-]/)[0];
    
                // Prompt the user to enter their first last name for verification
                const userFirstLastNameInput = prompt(language === 'English' 
                    ? "Please enter your first last name for verification:"
                    : "Por favor, ingrese su primer apellido para verificación:");
    
                // Check if the input is null (when Cancel is clicked) or empty
                if (!userFirstLastNameInput) {
                    window.location.reload();
                    return;
                }

                const mName = response.data.middleName  ?? "";
    
                // Normalize and compare both names
                if (normalizeString(userFirstLastNameInput) === normalizeString(firstLastName)) {
                    // If names match
                    setFirstLastNameUserInput(userFirstLastNameInput);
                    setDateOfBirth(response.data.dateOfBirth)
                    setApptFirstName(response.data.firstName);
                    setApptMiddleName(mName);
                    setApptLastName(response.data.lastName);
                    setEmail(response.data.email);
                    setOptIn(response.data.optIn);
                    setLanguage(response.data.language);
                    setDataExists(true);
                    
    
                    if (!response.data.optIn) {
                        alert(
                            language === 'English'
                                ? "Please ensure you've checked the box if you wish to receive notifications and updates. If you accept to receive these, all fields must also be completed."
                                : "Por favor, asegúrese de haber marcado la casilla si desea recibir notificaciones y actualizaciones. Si acepta recibir estas notificaciones, todos los campos también deben completarse."
                        );
                    }
                } else {
                    // If names don't match
                    alert(
                        language === 'English'
                            ? "Your first last name does not match the information on our records. Please contact us to help you fix this issue."
                            : "Su primer apellido no coincide con la información en nuestros registros. Por favor, contáctenos para ayudarlo a solucionar este problema."
                    );
                    // Reload the app
                    window.location.reload();
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                alert(
                    language === 'English' 
                    ? "The details you entered do not match the ones in our database. Please try again."
                    : "Los detalles que ingresó no coinciden con los que están en nuestra base de datos. Por favor, intente de nuevo."
                );
                window.location.reload(); // Reload the page
            } else {
                console.error("An error occurred:", error);
            }
        }
    };
    
    

    const handleSendOtp = async () => {
        if (isSending) {
            return;  // Exit the function if OTP is currently being sent.
        }
    
        setIsSending(true);  // Indicate that the OTP is being sent.
    
        try {
            await axios.post('https://nproapiserver.herokuapp.com/sendOtp', { phoneNumber });
            setOtpSent(true);
        } catch (error) {
            if (error.response && error.response.status === 500) {
                alert(language === 'English' ? 'You entered an invalid phone number. Please check your number and try again.' : 'Ingresó un número de teléfono no válido. Verifique su número e intente de nuevo.');
                window.location.reload();
            } else {
                alert(language === 'English' ? 'Something went wrong. Please try again later.' : 'Algo salió mal. Inténtelo de nuevo más tarde.');
                window.location.reload();
            }
        }
    };


    useEffect(() => {
      if (correctedNumber) {
          handleSendOtp();
          setCorrectedNumber(false);
      }
  }, [correctedNumber]);
    
  
const correctedPhoneNumber = async (phoneNumber) => {
    setPhoneNumber('1' + phoneNumber);
    setCorrectedNumber(true);
};


const checkPhone = async () => {
  // Check if either phoneNumber or dateOfBirth is empty
  if (!phoneNumber || !dateOfBirthInput) {
      alert("Please fill all the required fields.");
      return;
  }

  // Validate phone number format
  if (phoneNumber.length === 10  && phoneNumber[0] !== '1') {
      // Valid 10-digit number, prepend '1'
      await correctedPhoneNumber(phoneNumber);

  } else if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
      // Valid 11-digit number starting with '1'
      handleSendOtp();
  } else {
      // Invalid number
      alert("Invalid phone number. Please enter a 10-digit number or an 11-digit number starting with '1'.");
      window.location.reload();
  }
};
    
    // const checkPhone = async () => {
    //     if (phoneNumber.length === 10 && phoneNumber[0] !== '1') {
    //         setPhoneNumber('1' + phoneNumber);
    //     } else {
    //         handleSendOtp();
    //     }
    // };

    

    const handleVerifyOtp = async () => {
        try {
            const response = await axios.post('https://nproapiserver.herokuapp.com/verifyOtp', { phoneNumber, otp });
            if (response.data.verified) {
                setOtpVerified(true);
                setOtpSent(false);
            } else {
                alert(language === 'English' ? "Incorrect OTP. Please try again." : "OTP incorrecto. Por favor intente de nuevo.");
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                alert(language === 'English' ? 'The entered number is incorrect. Please try again.' : 'El numero ingresado es incorrecto. Por favor trate de nuevo.');
            } else {
                // This is a generic error alert, you might want to display more specific messages based on the exact error or status code.
                alert(language === 'English' ? 'An error occurred. Please try again later.' : 'Ocurrió un error. Por favor intente de nuevo más tarde.');
            }
        }
    };
    

    const findNextSpot = async () => {
        // console.log("encType: ", encType);
        setIsSearching(true);
        try {
          const searchDate = lastSearchDate ?? new Date().toISOString().split('T')[0]; // Use last search date or today
          
            // console.log(searchDate);
            // console.log(accountEmail);
            // console.log(providerName);
            // console.log(assocCal);
            // console.log(encType);

          const response = await axios({
            method: 'post',
            url: 'https://us-central1-notify-pro-accb3.cloudfunctions.net/findNextAvailableSpot',
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              userEmail: accountEmail,
              physician: providerName,
              selectedCalendar: assocCal,
              encounterType: encType,
              apptDate: searchDate,
              apptTime: "01:00 AM"
            }
          });
          
          setSearchResult(response.data);
          if (response.data.date) {
            setLastSearchDate(response.data.date); // Update last search date
          }
        } catch (error) {
          console.error('Error finding next available spot:', error);
          setSearchResult({ error: 'Failed to find next available spot' });
        } finally {
          setIsSearching(false);
        }
      };


      // Add this helper function before handleAccept.
      const formatTimeWithDoubleDigits = (timeStr) => {
        const [time, period] = timeStr.split(' ');
        const [hours, minutes] = time.split(':');
        return `${hours.padStart(2, '0')}:${minutes} ${period}`;
      };

      const handleAccept = async () => {
        const proceed = window.confirm(
            language === 'English' 
            ? "Disclaimer: If this appointment is part of a sequence of appointments, it may affect the overall outcome of your management. Also, please note that automation systems sometimes make mistakes, and in some cases, appointments made with the automation system may need to be reprogrammed. Do you wish to proceed?"
            : "Aviso: Si esta cita es parte de una secuencia de citas, puede afectar el resultado general de su manejo. Además, tenga en cuenta que los sistemas de automatización a veces cometen errores, y en algunos casos, las citas hechas con el sistema de automatización pueden necesitar ser reprogramadas. ¿Desea continuar?"
        );

        if (!proceed) {
            return;
        }

        try {
            const formattedTime = formatTimeWithDoubleDigits(searchResult.time);
            
            const newAppointment = {
                name: fullName,
                firstName: apptFirstName,
                middleName: apptMiddleName,
                lastName: apptLastName,
                phone: phoneNumber,
                date: searchResult.date,
                time: formattedTime, // Use the formatted time here
                email: patientEmail,
                encounterType: encType,
                language: xLanguage,
                dateOfBirth: dateOfBirth,
                aptSpecLabel: "Resch. from: " + oldDate,
                otherInfo: `${fullName} rescheduled from: ${oldDate}.\n\nPrevious label: ${aptSpecLabel ?? "none"}.\n\nPrevious other info: ${originalOtherInfo ?? "none"}`,
                oldDate: oldDate,
                oldTime: oldTime,
                oldEncType: oldEncType,
                orAppt: orAppt,
                originallyCreatedBy: originallyCreatedBy,
                originalApptDocId: originalApptDocId,
                originalOtherInfo: originalOtherInfo,
                rescheduleFromAppt: rescheduleFromAppt,
                rescheduledBy: `${fullName} rescheduled from: ${oldDate} on: ${new Date().toISOString()}`
            };

            if (fullName && phoneNumber && searchResult.date && searchResult.time && patientEmail && dateOfBirth) {
                await axios.post('https://nproapiserver.herokuapp.com/saveAppointmentFromAppt', {
                    accountEmail,
                    providerName,
                    assocCal,
                    newAppointment,
                    index
                });

                alert(language === 'English' ? 'Reschedule request sent successfully! You will be notified shortly if the appointment was successfully rescheduled via text message and email.' : '¡Solicitud de reprogramación enviada con éxito! Se le notificará en breve si la cita fue reprogramada con éxito mediante mensaje de texto y correo electrónico.');
                window.location.reload();

            } else {
                alert(language === 'English' ? 'Something went wrong with the request. Please try again.' : 'Algo salió mal con la solicitud. Por favor, inténtelo de nuevo.');
                window.location.reload();
            }
        } catch (error) {
            console.error("Error saving appointment: ", error);
            alert(language === 'English' ? 'Something went wrong with the request. Please try again.' : 'Algo salió mal con la solicitud. Por favor, inténtelo de nuevo.');
            window.location.reload();
        }
    };
    


    //   const handleAccept = async () => {

    //     const proceed = window.confirm(
    //         language === 'English' 
    //         ? "Disclaimer: If this appointment is part of a sequence of appointments, it may affect the overall outcome of your management. Also, please note that automation systems sometimes make mistakes, and in some cases, appointments made with the automation system may need to be reprogrammed. Do you wish to proceed?"
    //         : "Aviso: Si esta cita es parte de una secuencia de citas, puede afectar el resultado general de su manejo. Además, tenga en cuenta que los sistemas de automatización a veces cometen errores, y en algunos casos, las citas hechas con el sistema de automatización pueden necesitar ser reprogramadas. ¿Desea continuar?"
    //     );
    
    //     if (!proceed) {
    //         // If the client chooses not to proceed, exit the function
    //         return;
    //     }


    //     try {
    //         const newAppointment = {
    //             name: fullName,
    //             phone: phoneNumber,
    //             date: searchResult.date,
    //             time: searchResult.time,
    //             email: patientEmail,
    //             encounterType: encType,
    //             language: xLanguage,
    //             dateOfBirth: dateOfBirth,
    //             aptSpecLabel: "Resch. from: " + oldDate,
    //             otherInfo: fullName + " rescheduled from: " + oldDate,
    //             oldDate: oldDate,
    //             oldTime: oldTime,
    //             oldEncType: oldEncType
    //         };
    
    //         if (fullName && phoneNumber && searchResult.date && searchResult.time && patientEmail && dateOfBirth) {
    //             const response = await axios.post('https://nproapiserver.herokuapp.com/saveAppointment', {
    //                 accountEmail,
    //                 providerName,
    //                 assocCal,
    //                 newAppointment
    //             });

    //             alert(language === 'English' ? 'Reschedule request sent successfully! You will be notified shortly if the appointment was succesfully rescheuled via text message and email.' : '¡Solicitud de reprogramación enviada con éxito! Se le notificará en breve si la cita fue reprogramada con éxito mediante mensaje de texto y correo electrónico.');

    //             if (response.data.status === 'success') {
    //                 // alert(language === 'English' ? 'Reschedule request sent successfully! You will be notified shortly if the appointment was succesfully rescheuled via text message and email.' : '¡Solicitud de reprogramación enviada con éxito! Se le notificará en breve si la cita fue reprogramada con éxito mediante mensaje de texto y correo electrónico.');
    //                 console.log('Appointment saved successfully!');
    //                 window.location.reload(); // Reload the page
    //             } else {
    //                 // alert(language === 'English' ? 'Failed to save appointment. Please try again later.' : 'Error al guardar la cita. Por favor, inténtelo de nuevo más tarde.');
    //                 console.error('Failed to save appointment:', response.data.message);
    //             }
    //         } else {
    //             alert(language === 'English' ? 'Contact support.' : 'Contacte a soporte tecnico.');
    //         }
    //     } catch (error) {
    //         console.error("Error saving appointment: ", error);
    //         // alert(language === 'English' ? 'Failed to save appointment. Please try again later.' : 'Error al guardar la cita. Por favor, inténtelo de nuevo más tarde.');
    //     }
    // };
    
    
    //   const handleAccept = () => {
    //     // Implement the logic for accepting the appointment
    //     console.log('Appointment accepted:', searchResult);
    //     alert('ERROR!!! Appointment NOT saved! This feature is under development. Coming Soon!');
    //   };

      useEffect(() => {
        let interval;
        if (isSearching) {
          interval = setInterval(() => {
            setDotCount((prevCount) => (prevCount + 1) % 4);
          }, 500); // Adjust the speed here (500ms between each dot change)
        } else {
          setDotCount(0);
        }
        return () => clearInterval(interval);
      }, [isSearching]);
    
    
      const dots = '.'.repeat(dotCount); // Creates the string of dots based on dotCount

    //   const getDisplayValue = (encType, linkedEncounterTypes) => {
    //     encType = encType.toLowerCase();
    //     for (const docName in linkedEncounterTypes) {
    //       const linkedArray = linkedEncounterTypes[docName].linked.map(item => item.toLowerCase());
    //       if (linkedArray.includes(encType)) {
    //         return docName;
    //       } else if (docName.includes('*')) {
    //         // Remove the asterisk from docName and set encType to docName
    //         const cleanedDocName = docName.replace('*', '');
    //         encType = cleanedDocName;
    //         setEncType(cleanedDocName);
    //         return cleanedDocName;
    //       }
    //     }
    //     return encType;
    //   };


    const getDisplayValue = (encType, linkedEncounterTypes) => {
        encType = encType.toLowerCase();
      
        for (const docName in linkedEncounterTypes) {
          const linkedArray = linkedEncounterTypes[docName].linked.map(item => item.toLowerCase());
      
          if (linkedArray.includes(encType)) {
            if (docName.includes('*')) {
              // Remove the asterisk from docName and set encType to the cleaned docName
              const cleanedDocName = docName.replace('*', '');
            //   encType = cleanedDocName; // This line seems redundant if you're calling setEncType(cleanedDocName) right after
              setEncType(cleanedDocName); // Assuming this is a state-setting function
              return cleanedDocName.toUpperCase();
            } else {
              return docName.toUpperCase(); // Return the original docName if no asterisk is found
            }
          }
        }
      
        // Return the original encType if no match is found
        return encType;
      };
      
      

    //   const getDisplayValue = (encType, linkedEncounterTypes) => {
    //     encType = encType.toLowerCase();
    //     for (const docName in linkedEncounterTypes) {
    //       const linkedArray = linkedEncounterTypes[docName].linked.map(item => item.toLowerCase());
    //       if (linkedArray.includes(encType)) {
    //         if (docName.includes('*')) {
    //             // Remove the asterisk from docName and set encType to docName
    //             const cleanedDocName = docName.replace('*', '');
    //             encType = cleanedDocName;
    //             setEncType(cleanedDocName);
    //             return cleanedDocName;
    //             } else {
    //         return docName;
    //       }
    //     }
    //     return encType;
    //   };
    
      function formatDateCustom(dateString, language) {
        const [year, month, day] = dateString.split('-');
        
        const months = {
          English: [
            'January', 'February', 'March', 'April', 'May', 'June', 
            'July', 'August', 'September', 'October', 'November', 'December'
          ],
          Spanish: [
            'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 
            'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
          ]
        };
      
        const monthName = months[language === 'English' ? 'English' : 'Spanish'][parseInt(month) - 1];
      
        return `${day} ${monthName} ${year}`;
      }






    return (
      <>
        {alreadyRescheduled ? (
          <div style={{ 
            backgroundColor: 'black', 
            color: 'white', 
            height: '100vh', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            flexDirection: 'column',
            padding: '20px',
            textAlign: 'center'
          }}>
            <h2>{language.toLowerCase() === "english" 
              ? "This appointment has already been rescheduled to:" 
              : "Esta cita ya fue reprogramada para el:"}
            </h2>
            <p>{alreadyRescheduledValue}</p>
          </div>
        ) : showMessageCard && message ? (
          <OtherMessageCard message={message} />
        ) : (
          <div className="client-info-form">
            <div style={{ marginBottom: '20px', marginTop: '15px' }}>
              <img src={logo} alt="Logo" className="logo"/>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <ToggleButtonGroup
                  value={language}
                  exclusive
                  onChange={(event, newLanguage) => {
                    event.stopPropagation();
                    if (newLanguage !== null) setLanguage(newLanguage);
                  }}
                  style={{ marginBottom: '20px', display: 'inline-flex' }}
                >
                  <ToggleButton 
                    value="Spanish"
                    style={{ 
                      width: '80px',
                      height: '30px',
                      padding: '4px 8px',
                      fontSize: '14px',
                      color: language === 'Spanish' ? '#fff' : '#707070',
                      backgroundColor: language === 'Spanish' ? '#1E90FF' : '#000'
                    }}
                  >
                    Español
                  </ToggleButton>
                  <ToggleButton 
                    value="English" 
                    style={{ 
                      width: '80px',
                      height: '30px',
                      padding: '4px 8px',
                      fontSize: '14px',
                      color: language === 'English' ? '#fff' : '#707070',
                      backgroundColor: language === 'English' ? '#1E90FF' : '#000'
                    }}
                  >
                    English
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            
            {otpVerified ? (
              <div>
<div style={{ fontSize: '20px', marginBottom: '20px', textAlign: 'center' }}>
  {language === 'English' ? 'Appointment --> ' : 'Cita --> '}
  {getDisplayValue(encType, linkedEncounterTypes).toUpperCase()}
</div>
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center', 
                  marginBottom: '60px', 
                  paddingRight: '0px', 
                  textAlign: 'center' 
                }}>
                  <span style={{ lineHeight: '1.5', marginBottom: '40px', fontSize: '22px', fontWeight: 'bold', textDecoration: 'underline' }}>
                    {language === 'English' ? "Press the NEXT SPOT button to start the search!" : "¡Presione el botón PRÓXIMO TURNO para comenzar la búsqueda!"}
                  </span>
                  {searchResult && (
                    <div style={{ marginBottom: '60px', textAlign: 'center' }}>
                      {searchResult.error ? (
                        <span>{searchResult.error}</span>
                      ) : (
                        <span style={{ fontSize: '20px' }}>
                          {`${formatDateCustom(searchResult.date, language)}`}
                          <br />
                          {`${language === 'English' ? 'at' : 'a las'} ${searchResult.time}`}
                        </span>
                      )}
                    </div>
                  )}
                  <div style={{ textAlign: 'center' }}>
                    <button 
                      onClick={findNextSpot} 
                      disabled={isSearching}
                      style={{ padding: '10px 15px', marginRight: '10px' }}
                    >
                      {isSearching 
                        ? (language === 'English' ? `Searching${dots}` : `Buscando${dots}`) 
                        : (language === 'English' ? 'Next Spot' : 'Próximo Turno')}
                    </button>
                    {searchResult && !searchResult.error && !isSearching && (
                      <button 
                        onClick={handleAccept}
                        style={{ padding: '10px 15px' }}
                      >
                        {language === 'English' ? 'Accept' : 'Aceptar'}
                      </button>
                    )}
                  </div>
                  <div style={{ fontSize: '12px', marginBottom: '20px', textAlign: 'center', marginTop: '30px' }}>
                    {language === 'English' ? '*The search process may take several minutes after each button press. please be patient.' : '*El proceso de búsqueda puede tardar varios minutos luego de cada clic al botón. Por favor, sea paciente.'}
                  </div>
                </div>
              </div>
            ) : otpSent ? (
              <div>
                <div style={{ marginBottom: '15px' }}>
                  <input
                    type="text"
                    placeholder={language === 'English' ? 'Enter OTP' : 'Ingrese OTP'}
                    value={otp}
                    onChange={e => setOtp(e.target.value)}
                    style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                  />
                </div>
                <div>
                  <button 
                    onClick={handleVerifyOtp} 
                    style={{ width: '100%', padding: '15px', fontSize: '16px' }}
                  >
                    {language === 'English' ? 'Verify OTP' : 'Verificar OTP'}
                  </button>
                </div>
              </div>
            ) : (


              <div>



                {/* <div style={{ marginBottom: '15px' }}>
                  <input
                    type="text"
                    placeholder={language === 'English' ? 'Enter your mobile phone number' : 'Ingrese su número de teléfono celular'}
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                  />
                </div> */}



                {/* <div style={{ marginBottom: '15px' }}>
                  <input
                    type="text"
                    placeholder={language === 'English' ? 'Enter your mobile phone number' : 'Ingrese su número de teléfono celular'}
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    onFocus={e => {
                      if (!dateOfBirthInput || dateOfBirthInput === "") {
                      setIsDateEmpty(true);   // Set isDateEmpty to false on focus
                      }
                    }}
                    style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                  />
                </div> */}






                
                {/* <div style={{ marginBottom: '15px', position: 'relative' }}>
                  <input
                    id="dateOfBirth"
                    type={isDateEmpty ? "text" : "date"} // Switch type based on whether date is set
                    value={dateOfBirthInput}
                    onChange={e => setDateOfBirthInput(e.target.value)}
                    onFocus={e => {
                      e.target.type = 'date';  // Ensure input changes to 'date'
                      setIsDateEmpty(false);   // Set isDateEmpty to false on focus
                    }}
                    onBlur={e => {
                      if (!dateOfBirthInput) {
                        e.target.type = "text"; // Back to "text" if empty on blur
                      }
                      if (!dateOfBirthInput || dateOfBirthInput === "") {
                        setIsDateEmpty(true);   // Set isDateEmpty to true if input is empty
                      }
                    }}                    
                    style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                  />
                  {isDateEmpty && (
                    <span style={{
                      position: 'absolute', 
                      top: '10px', 
                      left: '10px', 
                      color: '#666666', 
                      pointerEvents: 'none'
                    }}>
                      {language === 'English' ? 'Date of Birth' : 'Fecha de Nacimiento'}
                    </span>
                  )}
                </div> */}





<div style={{ marginBottom: '8px', marginTop: '-8px' }}>
  <input
    type="text"
    placeholder={language === 'English' ? 'Enter your mobile phone number' : 'Ingrese su número de teléfono celular'}
    value={phoneNumber}
    onChange={e => setPhoneNumber(e.target.value)}
    onFocus={() => {
      if (!dateOfBirthInput || dateOfBirthInput === "") {
        setIsDateEmpty(true);
      }
    }}
    style={{
      width: '100%',
      padding: '10px',
      boxSizing: 'border-box',
      textAlign: isAppleDevice ? 'center' : 'left' // Center on Apple devices, left-align on others
    }}
  />
</div>


<div style={{ marginBottom: '8px'}}>
  <input
    type="text"
    placeholder={language === 'English' ? 'Enter your first name' : 'Ingrese su primer nombre'}
    value={firstNameInput}
    onChange={e => setFirstNameInput(e.target.value)}
    style={{
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box',
        textAlign: isAppleDevice ? 'center' : 'left' // Center on Apple devices, left-align on others
      }}
  />
</div>



<div
  style={{
    marginBottom: '50px',
    position: 'relative', // Keep the container positioned relatively
    width: '100%',
  }}
>
<input
  id="dateOfBirth"
  type="date"
  value={dateOfBirthInput}
  onChange={(e) => setDateOfBirthInput(e.target.value)}
  style={{
    width: '100%',
    padding: '15px 10px 10px 10px', // Adjust padding to make space for the label
    boxSizing: 'border-box',
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #ccc',
    borderRadius: '10px', // Keep the rounded corners
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    margin: 0,
    height: '40px',
    textAlign: 'left',
    WebkitTextAlign: 'left',
    MozTextAlign: 'left',
  }}
/>

  {!dateOfBirthInput && !isDesktop && (
    <label
      htmlFor="dateOfBirth"
      onClick={() => document.getElementById('dateOfBirth').focus()}
      style={{
        position: 'absolute',
        top: '10px',
        left: '11px',
        color: isAppleDevice ? '#AAAAAA' : '#707070',
        fontSize: '16px',
        cursor: 'text',
        pointerEvents: 'none',
      }}
    >
      {language === 'English' ? 'Date of birth' : 'Fecha de nacimiento'}
    </label>
  )}
</div>








                {/* <div style={{ marginBottom: '15px', position: 'relative' }}>
                  <input
                    id="dateOfBirth"
                    type="date"
                    value={dateOfBirthInput}
                    onChange={e => setDateOfBirthInput(e.target.value)}
                    style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                  />
                  {isDateEmpty && (
                    <span style={{
                      position: 'absolute', 
                      top: '10px', 
                      left: '10px', 
                      color: '#666666', 
                      pointerEvents: 'none'
                    }}>
                      {language === 'English' ? 'Date of Birth' : 'Fecha de Nacimiento'}
                    </span>
                  )}
                </div> */}
                <div>
                  <button 
                    onClick={checkPhone} 
                    disabled={isSending} 
                    style={{ width: '100%', padding: '15px', fontSize: '16px' }}
                  >
                    {isSending ? (language === 'English' ? 'Sending...' : 'Enviando...') : (language === 'English' ? 'Send OTP' : 'Enviar OTP')}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );

}



export default RescheduleFromApptForm;
