import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import Notification from './Notification';
import ClientInfoForm from './ClientInfoForm';
import RescheduleForm from './RescheduleForm';
import RescheduleFromApptForm from './RescheduleFromApptForm';
import axios from 'axios';
import './Notification.css';





function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function MessageCard({ message, accEmail, lang, providerName, cancelledStatus, rulesStatus, index, date }) {
  const notificationPreferencesUrl = `https://www.myappointment.notify-pro.com/optInfo?accountEmail=${encodeURIComponent(accEmail)}&providerName=${encodeURIComponent(providerName)}`;
  // const notificationPreferencesUrl = `https://npro-sms-121ca5186194.herokuapp.com/optInfo?accountEmail=${encodeURIComponent(accEmail)}`;

  const buttonTexts = {
    English: {
      notificationPreferences: "CLICK HERE TO EDIT YOUR NOTIFICATION PREFERENCES",
      // closeWindow: "CLOSE WINDOW"
    },
    Spanish: {
      notificationPreferences: "PRESIONE AQUÍ PARA EDITAR SUS PREFERENCIAS DE NOTIFICACIÓN",
      // closeWindow: "CERRAR VENTANA"
    }
  };

  const rescheduleUrl = `https://www.myappointment.notify-pro.com/rescheduleAppt?index=${encodeURIComponent(index)}&accountEmail=${encodeURIComponent(accEmail)}&providerName=${encodeURIComponent(providerName)}`;
  // const notificationPreferencesUrl = `https://npro-sms-121ca5186194.herokuapp.com/optInfo?accountEmail=${encodeURIComponent(accEmail)}`;

  const rescheduleButtonTexts = {
    English: {
      rescheduleBtn: "RESCHEDULE MY APPOINTMENT",
      // closeWindow: "CLOSE WINDOW"
    },
    Spanish: {
      rescheduleBtn: "REPROGRAMAR MI CITA",
      // closeWindow: "CERRAR VENTANA"
    }
  };

  const currentTexts = buttonTexts[lang] || buttonTexts['English']; // Default to English if `lang` is not recognized

  const currentTexts2 = rescheduleButtonTexts[lang] || buttonTexts['English']; // Default to English if `lang` is not recognized


  return (
    <div className="message-card-container">
      <div className="message-card">
        <p>{message}</p>
      </div>


      {cancelledStatus === true && rulesStatus === true && new Date(date) >= new Date() && (
      <a href={rescheduleUrl} className="reschedule-button" target="_blank" rel="noopener noreferrer">
        <strong>{currentTexts2.rescheduleBtn}</strong>
      </a>
    )}

      <a href={notificationPreferencesUrl} className="notif-preferences-button" target="_blank" rel="noopener noreferrer">
        <strong>{currentTexts.notificationPreferences}</strong>
      </a>
    </div>
  );

}



const handleCloseTab = () => {
  window.close();
}

function NotificationPage() {
  let query = useQuery();
  const [data, setData] = useState({ title: '', message: '' });
  const [message, setMessage] = useState('');
  const [initialMessage, setInitialMessage] = useState(''); 
  const [xLanguage, setXLanguage] = useState('');
  const [logo, setLogo] = useState('');
  const [showMessageCard, setShowMessageCard] = useState(false);
  const [cancelledStatus, setCancelledStatus] = useState(false);
  const [rulesStatus, setRulesStatus] = useState(false);
  const [date, setDate] = useState('');
  const [alreadyRescheduled, setAlreadyRescheduled] = useState(false);
  const [alreadyRescheduledValue, setAlreadyRescheduledValue] = useState("");


  let index = query.get("index");
  let providerName = query.get("providerName");
  let accountEmail = query.get("accountEmail"); 


  useEffect(() => {
    axios.get(`https://nproapiserver.herokuapp.com/getMessage`, {
      params: {
        index: index,
        providerName: providerName,
        accountEmail: accountEmail
      }
    })
    .then(response => {
      setInitialMessage(response.data.message);
      setXLanguage(response.data.xLanguage); // storing xLanguage
  
      let logoUrl = response.data.logo;
  
      // If the logo string starts with '<img src=' or '<img src="', extract the URL
      if (logoUrl.startsWith('<img src=\'') || logoUrl.startsWith('<img src="')) {
        // Extract URL using regular expression
        let match = logoUrl.match(/<img src=['"](.*?)['"]/);
        if (match) {
          logoUrl = match[1]; // Get the matched group (URL)
        }
      }

      // console.log(response.data);
  
      setLogo(logoUrl); // storing logo
      setRulesStatus(response.data.rulesStatus);
      setDate(response.data.date);
      if (response.data.alreadyRescheduled.length > 0) {
        // alert("You have already rescheduled this appointment.");
        setAlreadyRescheduled(true);
        setAlreadyRescheduledValue(response.data.alreadyRescheduled);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
  }, [index, providerName, accountEmail, xLanguage]);

  const handleConfirm = () => {
    const today = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
  
    if (date < today) {
      alert('Cannot confirm past events.');
      return;
    }
  
    axios.get(`https://nproapiserver.herokuapp.com/confirmSmsByApp`, {
      params: {
        index: index,
        providerName: providerName,
        accountEmail: accountEmail,
        xLanguage: xLanguage
      }
    })
      .then(response => {
        setMessage(response.data.message);
        setShowMessageCard(true); // Hide Notification and show MessageCard
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };
  
  const handleCancel = () => {
    const today = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
  
    if (date < today) {
      alert('Cannot cancel past events.');
      return;
    }
  
    // console.log("handleCancel executed with: ", index, providerName, accountEmail, xLanguage, rulesStatus);
    axios.get(`https://nproapiserver.herokuapp.com/cancelSmsByApp`, {
      params: {
        index: index,
        providerName: providerName,
        accountEmail: accountEmail,
        xLanguage: xLanguage,
        rulesStatus: rulesStatus,
        date: date
      }
    })
      .then(response => {
        // console.log("Response from cancelSmsByApp: ", response.data);
        setMessage(response.data.message);
        setShowMessageCard(true); // Hide Notification and show MessageCard
        setCancelledStatus(true);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };
  

  // const handleConfirm = () => {
  //   axios.get(`https://nproapiserver.herokuapp.com/confirmSmsByApp`, {
  //     params: {
  //       index: index,
  //       providerName: providerName,
  //       accountEmail: accountEmail,
  //       xLanguage: xLanguage
  //     }
  //   })
  //     .then(response => {
  //       setMessage(response.data.message);
  //       setShowMessageCard(true); // Hide Notification and show MessageCard
  //     })
  //     .catch(error => {
  //       console.error('There was an error!', error);
  //     });
  // };

  // const handleCancel = () => {
  //   console.log("handleCancel executed with: ", index, providerName, accountEmail, xLanguage, rulesStatus);
  //   axios.get(`https://nproapiserver.herokuapp.com/cancelSmsByApp`, {
  //     params: {
  //       index: index,
  //       providerName: providerName,
  //       accountEmail: accountEmail,
  //       xLanguage: xLanguage,
  //       rulesStatus: rulesStatus,
  //       date: date
  //     }
  //   })
  //     .then(response => {
  //       console.log("Response from cancelSmsByApp: ", response.data);
  //       setMessage(response.data.message);
  //       setShowMessageCard(true); // Hide Notification and show MessageCard
  //       setCancelledStatus(true);
  //     })
  //     .catch(error => {
  //       console.error('There was an error!', error);
  //     });
  // };
  

  return (
    <div className="notification-page">
      {!showMessageCard ? (
        <Notification 
          title={data.title}
          message={initialMessage}  
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          logo={logo}
          xLanguage={xLanguage}
          alreadyRescheduled={alreadyRescheduled}
          alreadyRescheduledValue={alreadyRescheduledValue}
          accountEmail={accountEmail}
          providerName={providerName}
        />
      ) : null}
      
      {showMessageCard && message && (
        <MessageCard 
          message={message} 
          accEmail={accountEmail} 
          lang={xLanguage} 
          providerName={providerName} 
          cancelledStatus={cancelledStatus} 
          rulesStatus={rulesStatus} 
          index={index}
          date={date}
        />
      )}
    </div>
  );
}




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<NotificationPage />} />
        <Route path="/optInfo" element={<ClientInfoForm />} />
        <Route path="/clientinfo" render={() => <ClientInfoForm onCloseTab={handleCloseTab} />} />
        <Route 
          path="/rescheduleAppt" 
          element={
            <RescheduleForm
            />
          } 
        />
                <Route 
          path="/rescheduleFromLink" 
          element={
            <RescheduleFromApptForm
            />
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;
